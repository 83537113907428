<template>
    <b-modal id="alternativeArticle" title="Artikelalternative hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" hide-footer v-if="articles != null">
        <div class="col-md-12 text-right">
            <div class="input-group input-group-sm" style="margin-top: 0;">
                <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadAllArticles" :value="search" @input="$store.commit('routes/changeSearch', $event.target.value)" />
                <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadAllArticles">
                    <i class="fas fa-fw fa-search"></i>
                </button>
            </div>
        </div>
        <table class="table table-hover table-responsive-sm">
            <thead>
                <tr>
                    <th>Artikelnummer</th>
                    <th>Name</th>
                    <th>Aktionen</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(article) in articles.data" >
                    <tr :key="article.id">
                        <td>{{article.ordernumber}}</td>
                        <td>{{article.article.name}}</td>
                        <td>
                            <button class="btn btn-primary btn-sm" v-b-popover.hover.top="'Hinzufügen'" @click="add(article)">
                                <i class="fas fa-plus"></i>
                            </button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <template v-if="articles.data.length > 0">
            <div class="row align-items-center" style="width: 100%">
                <div class="col-md-9">
                    <pagination class="float-left" :data="articles" @pagination-change-page="loadAllArticles" :limit="3"></pagination>
                </div>
                <div class="col-md-2">
                    <span class="float-right">Anzeige Eintrag {{ articles.meta.from }} - {{ articles.meta.to }} von {{ articles.meta.total }}</span>
                </div>
                <div class="col-md-1">
                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                        <option value="10">10 Artikel</option>
                        <option value="25">25 Artikel</option>
                        <option value="50">50 Artikel</option>
                        <option value="75">75 Artikel</option>
                        <option value="100">100 Artikel</option>
                        <option value="125">125 Artikel</option>
                        <option value="150">150 Artikel</option>
                    </select>
                </div>
            </div>                        
        </template>
        <div class="card-tools text-right">
            <button class="btn btn-secondary btn-sm" @click="closeDriversMessageModal">
                Schließen
            </button>
        </div>
    </b-modal>
</template>

<script>
import {mapState} from 'vuex';
export default {
    name: 'RouteDetailAddAlternativeArticleModal',
    props: [
        'not_available'
    ],
    data() {
        return {
            articles: null,
        }
    },
    methods: {
        closeDriversMessageModal() {
            this.$bvModal.hide('alternativeArticle');
        },
        loadAllArticles(page)
        {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get('/details', {
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.articles = response.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        add(detail)
        {
            let alternativs = this.not_available.id.alternative_article;
            if(alternativs != null)
            {
                let article = alternativs.find(element => element.detail_id == detail.id);
                if(article == null)
                {
                    this.not_available.id.alternative_article.push({
                        detail_id: detail.id,
                        name: detail.article.name,
                        ordernumber: detail.ordernumber,
                        qty: 1
                    });
                } else
                {
                    article.qty += 1;
                }
            } else
            {
                this.not_available.id.alternative_article = [];
                this.not_available.id.alternative_article.push({
                    detail_id: detail.id,
                    name: detail.article.name,
                    ordernumber: detail.ordernumber,
                    qty: 1
                });
            }

            this.$bvModal.hide('alternativeArticle');
        }
    },
    computed: {
        ...mapState('routes', ['params', 'search', 'page']),
    },
    mounted() {
        this.loadAllArticles();
    }
}
</script>

<style>

</style>