<template>
    <b-modal id="proof_of_delivery" title="Streckenabhängigkeit festlegen" ok-only ok-variant="danger" ok-title="Speichern" size="l" hide-footer hide-header>
        <button class="btn btn-primary-outline btn-l" style="position: absolute; top: 2%; right: 2%;" @click="closeDeliveryImage">
            <i class="fas fa-times" style="font-size: 30px;"></i>
        </button>
        <div style=" margin-left: auto; margin-right: auto; padding: 0;">
            <img v-bind:src="img" v-bind:alt="img" style="width: 100%;margin-left: auto; margin-right: auto;display: block;">
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'RouteDetailProofOfDeliveryModal',
    props: [
        'img'
    ],
    methods: {
        closeDeliveryImage()
        {
            this.$bvModal.hide('proof_of_delivery');
        },
    }
}
</script>

<style>

</style>