<template>
    <b-modal id="notice" title="Notizen" ok-only ok-variant="danger" ok-title="Speichern" size="xs" hide-footer>
        <div v-if="id != null">
            <textarea v-if="route.route_elements[id] != null" v-model="route.route_elements[id].info" style="width: 100%; height: 100px;" :disabled="route.route_elements[id].status.name != 'open'"></textarea>
        </div>
        <div class="card-tools text-right">
            <button class="btn btn-secondary btn-sm" @click="closeNoticeModal">
                Schließen
            </button>
        </div>
    </b-modal>
</template>

<script>
export default {
    name: 'RouteDetailNoticeModal',
    props: [
        'route',
        'id'
    ],

    methods: {
        closeNoticeModal()
        {  
            this.$bvModal.hide('notice');
        },
    }
}
</script>

<style>

</style>