<template>
    <GmapMap
        :center="{lat:51.4157678, lng:7.9163869}"
        :zoom="10"
        map-type-id="terrain"
        style="width: 100%; min-height: 500px; height: 100%;"
        :options="{
            streetViewControl: false,
        }"
    >
        <template v-for="(location, index) in route.route_elements">
            <GmapMarker :zIndex="(location.position+1)*-1" 
                :label="location.position+1+''" 
                :position="google && new google.maps.LatLng(parseFloat(location.latitude), parseFloat(location.longitude))" 
                :key="location.id"
                @mouseover="showByIndex = index"
                @mouseout="showByIndex = null"
            >
                <gmap-info-window :opened="showByIndex === index" style="width: 100%">
                    <b>{{$parent.generateName(route.route_elements[showByIndex])}}</b>  <br>
                    
                </gmap-info-window>
            </GmapMarker>
        </template>
    </GmapMap>
</template>

<script>
import { gmapApi } from 'gmap-vue';
export default {
    name: 'RouteDetailMap',
    props: [
        'route'
    ],
    data() {
        return {
            showByIndex: null,
        }
    },

    computed: {
        google: gmapApi,
    },
    mounted() {

    }
}
</script>

<style>

</style>