<template>
    <b-container fluid>
        <div class="card card-primary card-outline" v-if="route != null">
            <div class="card-header">
                <div class="card-title">
                    Route bearbeiten
                </div>
                <div class="card-tools">
                    <button class="btn btn-default" @click="reload()">
                        <i class="fas fa-sync"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="card card-success card-outline" style="width: 100%">
                        <div class="card-header">
                            <div class="card-title">
                                Allgemeines
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="card-tools">
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Bezeichnung*</label>
                                            <div class="col-md-9">
                                                <input type="text" class="form-control form-control-sm" v-model="route.name">
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Fahrzeug*</label>
                                            <div class="col-md-9">
                                                <select class="form-control form-control-sm" v-model="route.car_id">
                                                    <option :value="0">-- Filter Fahrzeuge --</option>
                                                    <option v-for="car in cars" :key="car.id" :value="car.id">{{car.name}}</option>
                                                </select>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Auslieferungstag*</label>
                                            <div class="col-md-9">
                                                <input type="date" class="form-control form-control-sm" v-model="route.deliveryDay">
                                            </div>   
                                        </div>
                                    </div>
                                    <div class="col-md-5">
                                        <div class="form-group row">
                                            <label class="col-md-3 col-form-label">Fahrer</label>
                                            <div class="col-md-9">
                                                <select class="form-control form-control-sm" v-model="route.employee_id">
                                                    <option :value="null">-- Bitte wählen --</option>
                                                    <!-- <option v-for="employee in employees" :key="employee.id">{{employee.first_name}} {{employee.last_name}}</option> -->
                                                    <optgroup v-for="group in groups" :key="group.id" :label="group.name">
                                                        <option v-for="employee in group.employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
                                                    </optgroup>
                                                </select>
                                            </div>   
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer text-right">
                            <button class="btn btn-primary" @click="changeOrder" :disabled="route.car_id == null || route.name == null || route.name == '' || route.deliveryDay == '' || route.car_id == 0" style="margin-right: 5px;"><i class="fas fa-save"></i> Speichern</button>
                        </div>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-md-7">

                    </div>
                    <div class="col-md-5 text-center">
                        <h5>Strecke: {{String(route_details.distance / 1000).replace('.',',') }}km</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-7">
                        <div class="card card-primary card-outline">
                            <div class="card-header">
                                <div class="card-title">
                                    Zwischenpunkte
                                </div>
                                <div class="card-tools">
                                    <label class="mr-3">
                                        Startzeit: {{this.route.start_time}}
                                    </label>
                                    <button type="button" class="mr-1 btn btn-success btn-sm" @click="perlschnur()" v-b-popover.hover.top="'Schnellste Strecke berechnen'">
                                        Umsortieren
                                    </button>
                                    <button type="button" class="mr-1 btn btn-primary btn-sm" @click="createModal" v-b-popover.hover.top="'Neuen Zwischenstopp hinzufügen'">
                                        <i class="fas fa-fw fa-plus"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body p-1" style="font-size: 13px;">
                                <table class="table table-hover">
                                    <tbody class="m-0">
                                        <draggable tag="ul" :list="route.route_elements" class="list-group" handle=".draggable" :move="checkMove" @change="drop">
                                            <li v-for="(element, index) in route.route_elements" :key="element.id" style="list-style: none;" class="m-1 " >
                                                <div class="accordion" role="tablist" >
                                                    <b-card no-body class="mb-1">
                                                        <b-card-header header-tag="header" role="tab" :class="element.status.name == 'completed' ? 'callout callout-success' : (element.status.name == 'next_station' ? 'callout callout-warning' : 'callout callout-danger')">
                                                            <div class="ribbon-wrapper ribbon-sm" v-if="checkConditionAfterSource(element) == true">
                                                                <div class="ribbon bg-danger text-l">
                                                                    <i class="fas fa-exclamation"></i>
                                                                </div>
                                                            </div>
                                                            <template v-if="element.orders != null">
                                                                <template v-if="element.orders.la_sended == 0">
                                                                    <div class="ribbon-wrapper ribbon-sm" v-if="element.orders.email != null">
                                                                        <div class="ribbon bg-danger text-xs" v-b-tooltip="'Lieferavisierung senden'" style="cursor: pointer;" @click="getLa(element.orders, index)">
                                                                            LA
                                                                        </div>
                                                                    </div>
                                                                    <div class="ribbon-wrapper ribbon-sm" v-if="element.orders.phone != null">
                                                                        <div class="ribbon bg-danger text-xs" v-b-tooltip="'Lieferavisierung senden'" style="cursor: pointer;" @click="phoneLA(element.orders, index)">
                                                                            LA
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </template>
                                                            <div class="row" style="padding: 5px;">
                                                                <div class="col-md-1 text-center">
                                                                    <!-- {{element.id}} -->
                                                                    <i class="fa fa-align-justify" :class="{ draggable: element.status.name == 'open' ? true : false}" v-if="element.status.name == 'open'" style="font-size: 20px"></i>
                                                                </div>
                                                                <div class="col-1 p-1">
                                                                    {{element.position + 1}}
                                                                </div>
                                                                <div class="col-2 p-1">
                                                                    {{generateName(element)}}
                                                                    <template v-if="element.orders != null">
                                                                        <button class="btn btn-xs btn-secondary" v-b-popover.top.hover="'Bestellung ansehen'" @click="showOrder(element.orders)">
                                                                            <i class="fas fa-clipboard"></i>
                                                                        </button>
                                                                    </template>
                                                                </div>
                                                                <div class="col-2 text-center">
                                                                    <div v-if="selectedRow[index] && selectedCell === 'status'" class="input-group">
                                                                        <select class="form-control form-control-sm" v-model="route.route_elements[index].status_id" @change="changeOrder">
                                                                            <template v-for="state in states">
                                                                                <option :key="state.id" :value="state.id">{{state.description}}</option>
                                                                            </template>
                                                                        </select>
                                                                        <div class="input-group-append">
                                                                            <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'status')"><i class="fas fa-times"></i></button>
                                                                        </div>
                                                                    </div>
                                                                    <span @dblclick="handleEditCell(index, 'status')" v-else class="mr-1 badge" :class="setBadge(element)">
                                                                        {{element.status.description}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-2 text-center">
                                                                    <template v-if="element.status.name == 'open' || element.status.name == 'next_station'">
                                                                        <div v-if="selectedRow[index] && selectedCell === 'type'" class="input-group">
                                                                        <select class="form-control form-control-sm" v-model="route.route_elements[index].type" @change="changeOrder">
                                                                            <template>
                                                                                <option value="pickup">Abholen</option>
                                                                                <option value="dropdown">Ausliefern</option>
                                                                            </template>
                                                                        </select>
                                                                        <div class="input-group-append">
                                                                            <button class="btn btn-sm btn-secondary" type="button" @click="handleEditCell(index, 'type')"><i class="fas fa-times"></i></button>
                                                                        </div>
                                                                    </div>
                                                                    <span @dblclick="handleEditCell(index, 'type')" v-else class="mr-1 badge badge-secondary">
                                                                        <template v-if="element.type == 'pickup'">
                                                                            Abholen
                                                                        </template>
                                                                        <template v-else>
                                                                            Ausliefern
                                                                        </template>
                                                                    </span>
                                                                    </template>
                                                                    <template v-else>
                                                                        <span class="mr-1 badge badge-secondary">
                                                                            <template v-if="element.type == 'pickup'">
                                                                                Abholen
                                                                            </template>
                                                                            <template v-else>
                                                                                Ausliefern
                                                                            </template>
                                                                        </span>
                                                                    </template>
                                                                </div>
                                                                <div class="col-2 p-1">
                                                                    <div class="display: inline">
                                                                        <template v-if="element.status.name == 'open'">
                                                                            <template v-if="element.info != null && element.info != ''">
                                                                                <button class="btn btn-light btn-xs mr-1" @click="openNoticeModal(index)" v-b-popover.hover.top="'Kommentar'">
                                                                                    <i class="fas fa-comment-alt" style="color: green"></i>
                                                                                </button>
                                                                            </template>
                                                                            <template v-else>
                                                                                <button class="btn btn-light btn-xs mr-1" @click="openNoticeModal(index)" v-b-popover.hover.top="'Kommentar'">
                                                                                    <i class="fas fa-comment-alt"></i>
                                                                                </button>
                                                                            </template>
                                                                        </template>
                                                                        <template v-if="element.drivers_message != null && element.drivers_message != ''">
                                                                            <button class="btn btn-danger btn-xs mr-2 pr-2 pl-2" @click="openDriversMessageModal(index)" v-b-popover.hover.top="'Fahrerkommentar'">
                                                                                <i class="fas fa-exclamation"></i>
                                                                            </button>
                                                                        </template>
                                                                        <button class="btn btn-primary btn-xs mr-1" @click="openDeliveryImage(element)" v-if="element.status.name == 'completed' && element.orders != null && element.deliveryImage != ''" v-b-popover.hover.top="'Nachweis ansehen'">
                                                                            <i class="fas fa-images"></i>
                                                                        </button>

                                                                        <!-- Wenn Offen -->
                                                                        <button class="btn btn-success btn-xs mr-1" v-if="element.status.name == 'open' && element.orders != null" v-b-popover.hover.top="'Artikel nicht vorhanden'" @click="openArticleModal(element)">
                                                                            <i class="fas fa-plus"></i>
                                                                        </button> 
                                                                        <button class="btn btn-warning btn-xs mr-1" @click="openConditionsModal(element)" v-if="element.status.name == 'open' && element.orders != null" v-b-popover.hover.top="'Bedingung hinzufügen'">
                                                                            <i class="fas fa-box-open"></i>
                                                                        </button>

                                                                        <button class="btn btn-danger btn-xs mr-1" @click="deleteElement(element.id, index)" v-if="element.status.name == 'open' && hasConditions(element) == false" v-b-popover.hover.top="'Löschen'">
                                                                            <i class="fas fa-trash"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div class="input-group input-group-sm col-2 text-right">
                                                                    {{calculateRouteElementArrivelTime(index)}}
                                                                </div>
                                                            </div>
                                                        <b-button block v-b-toggle="'accordion' + index" variant="success"  v-if="element.route_element_conditions.length > 0 || count(element) > 0">
                                                        </b-button>
                                                        </b-card-header>
                                                        <b-collapse v-bind:id="'accordion' + index" accordion="my-accordion" role="tabpanel" v-if="element.route_element_conditions.length > 0 || count(element) > 0">
                                                            <b-card-body class="p-1">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <table class="table table-hover table-xs text-center">
                                                                            <thead> 
                                                                                <tr>
                                                                                    <th style="width: 40%; padding: 1px" class="text-middle">Name</th>
                                                                                    <th style="width: 100%; padding: 1px">Artikel</th>
                                                                                    <th style="width: 100%; padding: 1px">Aktionen</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr v-for="(condition, conditionIndex) in element.route_element_conditions" :key="condition.id">
                                                                                    <td style="padding: 1px">
                                                                                        {{generateName(condition.target)}} <br>
                                                                                        <div class="badge bg-danger text-wrap" style="width: 6rem;cursor: pointer;"  @click="deleteCondition(condition.id, index, conditionIndex)" v-if="element.status.name == 'open'">
                                                                                            Löschen
                                                                                        </div>
                                                                                    </td>
                                                                                    <td style="padding: 1px; width: 100%" colspan="2">
                                                                                        <tr v-for="(article, articleIndex) in condition.items" :key="article.id">
                                                                                            <td style="padding: 1px; width: 100%" v-b-popover.hover.top="generateVariationName(article)" :class="article.status.name == 'fail' ? 'table-danger' : '' || article.status.name == 'success' ? 'table-success' : ''">
                                                                                                {{article.article_details.ordernumber}}
                                                                                                <template v-if="article.status.name == 'fail'">
                                                                                                    ({{article.qty - article.not_available}}/{{article.qty}})
                                                                                                </template>
                                                                                                <template v-else-if="article.status.name == 'success'">
                                                                                                    ({{article.qty}}/{{article.qty}})
                                                                                                </template>
                                                                                                <template v-else>
                                                                                                    (0/{{article.qty}})
                                                                                                </template>
                                                                                            </td>
                                                                                            <td style="padding: 1px; width: 100%">
                                                                                                <button class="btn btn-danger btn-xs" v-b-popover.hover.top="'Löschen'" @click="deleteConditionArticle(article.id, index, conditionIndex, articleIndex)" v-if="element.status.name == 'open'">
                                                                                                    <i class="fas fa-trash"></i>
                                                                                                </button>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </td>
                                                                                </tr>

                                                                                <template v-if="element.orders != null">
                                                                                    <tr style="margin-top: 50px;">
                                                                                        <td style="padding: 1px; padding-top: 20px;">
                                                                                            <b>Nicht lieferbare Artikel</b>
                                                                                        </td>
                                                                                        <td style="padding: 5px; padding-top: 20px; width: 100%" colspan="2">
                                                                                            <tr v-for="item in element.orders.items" :key="item.id">
                                                                                                <template v-if="item.not_available == 1">
                                                                                                    <td style="padding: 1px; padding-top: 10px; width: 100%">
                                                                                                        <div v-b-popover.hover.top="item.name">
                                                                                                            {{item.articleordernumber}} ({{item.not_available_qty}}x)
                                                                                                        </div>
                                                                                                        <template v-if="item.alternative_article != null && item.alternative_article.length > 0">
                                                                                                            <div class="text-left">
                                                                                                                <b>Alternative:</b>
                                                                                                            </div>
                                                                                                            <template v-for="(alt_item, alt_index) in item.alternative_article" >
                                                                                                                <div :key="alt_item.article_id">
                                                                                                                    <b style="padding-right: 40px;" v-b-popover.hover.top="alt_item.name">
                                                                                                                        {{alt_item.ordernumber}} ({{alt_item.qty}}x)
                                                                                                                    </b>
                                                                                                                    <b class="btn btn-danger btn-xs" style="padding: 1px;" v-b-popover.hover.top="'Alternative löschen'" @click="removeAlternativeArticle(element.id, item.id, alt_index)" v-if="element.status.name == 'open'">
                                                                                                                        <i class="fas fa-trash"></i>
                                                                                                                    </b>
                                                                                                                </div>
                                                                                                            </template>
                                                                                                        </template>
                                                                                                    </td>
                                                                                                    <td style="padding: 1px">
                                                                                                        <button class="btn btn-danger btn-xs" v-b-popover.hover.top="'Löschen'" @click="deleteArticleModal(element.id, item.id)" v-if="element.status.name == 'open'">
                                                                                                            <i class="fas fa-trash"></i>
                                                                                                        </button>
                                                                                                    </td>
                                                                                                </template>
                                                                                            </tr>
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </b-card-body>
                                                        </b-collapse>
                                                    </b-card>
                                                </div>
                                            </li>
                                        </draggable>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <Map :route="route" v-if="notEmptyObject(route)"/>
                    </div>
                </div>
            </div>
            <div class="card-footer text-right">
                <button class="btn btn-danger mr-1" @click="closeRoute()">
                    <i class="fas fa-warning"></i> Route Beenden
                </button>
                <button class="btn btn-primary" @click="changeOrder" :disabled="route.car_id == null || route.name == null || route.name == '' || route.deliveryDay == '' || route.car_id == 0" style="margin-right: 5px;"><i class="fas fa-save"></i> Speichern</button>
            </div>
        </div>
        


        <AddRouteElementModal :route="route"/>
        <NoticeModal :route="route" :id="id" v-if="notEmptyObject(route)"/>
        <DriversMessageModal :route="route" :id="id" v-if="notEmptyObject(route)"/>
        <ProofOfDeliveryModal :img="img" v-if="notEmptyObject(route)"/>
        <AddAlternativeArticle :not_available="not_available" v-if="notEmptyObject(route)" />


        <b-modal id="articleNotAvailable" title="Nicht Lieferbare Artikel hinzufügen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" hide-footer>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group row">
                        <label class="col-md-2">Artikel</label>
                        <select class="form-select col-md-4" v-model="not_available.id">
                            <option :value="null">-- Wähle Artikel --</option>
                            <template v-for="article in lists.articles">
                                <option :key="article.id" :value="article">
                                        {{article.articleordernumber}} | {{article.name}}
                                </option>
                            </template>
                        </select>
                        <template v-if="not_available.id != null">
                            <label class="col-md-2">Nicht Lieferbare Artikel</label> <br>
                            <select class="form-select col-md-4" v-model="not_available.id.not_available_qty">
                                <option value="0">-- Wähle Stückzahl --</option>
                                <option :value="index" v-for="index in not_available.id.quantity" :key="index">{{index}}</option>
                            </select>
                        </template>
                    </div>
                </div>
            </div>
            <template v-if="not_available.id != null">
                <div class="row">
                    <div class="col-md-9">
                        <h6>Alternativartikel</h6>
                    </div>
                    <div class="col-md-3 text-right">
                        <button class="btn btn-success" @click="openalternativeArticleModal()">
                            <i class="fas fa-plus"></i> Alternative
                        </button>
                    </div>
                </div>
                <table class="table table-hover table-responsive-sm">
                    <thead>
                        <tr>
                            <th>Artikelnummer</th>
                            <th>Name</th>
                            <th>Anzahl</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(article, i) in not_available.id.alternative_article">
                            <tr :key="article.detail_id">
                                <td>{{article.ordernumber}}</td>
                                <td>{{article.name}}</td>
                                <td>
                                    <input type="number" class="form-control form-control-sm" v-model="article.qty">
                                    <!-- <select class="form-select form-select-lg" v-model="article.qty">
                                        <option v-for="index in not_available.id.not_available+1" :key="index" :value="index">{{index}}</option>
                                    </select> -->
                                </td>
                                <td>
                                    <button class="btn btn-danger btn-xs" @click="removeAlternativeArticleModal(i)" v-b-popover.hover.top="'Alternative löschen'">
                                        <i class="fas fa-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </template>
            <div class="card-tools text-right">
                <button class="btn btn-primary btn-sm" @click="submitArticleModal(id, not_available.id)" :disabled="not_available.id == null || not_available.id.not_available_qty == 0">
                    <i class="fas fa-plus"></i> Hinzufügen
                </button>
            </div>
        </b-modal>
        <b-modal id="laModal" title="Lieferavisierung senden" size="xl" @ok="sendLA">
            <div class="form-group">
                <label for="">An:</label>
                <select class="form-control form-control-sm" multiple v-model="sendLaForm.selected_emails">
                    <option v-for="email in sendLaForm.emails" :key="email" :value="email">{{ email }}</option>
                </select>
            </div>
            <div class="form-group">
                <ckeditor :editor="editor" :config="editorConfig" v-model="sendLaForm.message"></ckeditor>
            </div>
        </b-modal>
        <b-modal id="laModalPhone" title="Lieferavisierung" size="xl" @ok="sendLA">
            <div class="text-center">
                <h3 v-html="sendLaForm.message"></h3>
            </div>
        </b-modal>
        <b-modal id="routeConditions" title="Streckenabhängigkeit festlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" hide-footer>
            <div class="text-right">
                <button class="btn btn-success btn-sm" @click="conditions.push({route_element_target_id: null, article_detail_id: null, qty: 1})">
                    <i class="fas fa-plus"></i>
                </button>
            </div>
            <table class="table table-hover table-striped">
                <thead>
                    <tr>
                        <th>Zwischenstopp</th>
                        <th>Artikel</th>
                        <th>Anzahl</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(condition, index) in conditions" :key="condition.rouite_element">
                        <td>
                            <select class="form-control form-control-sm" v-model="condition.route_element_target_id">
                                <option :value="null">-- Wähle Zwischenstopp --</option>
                                <template v-for="(element, index) in route.route_elements">
                                    <option :key="element.id" :value="element.id" v-if="element.type == 'pickup' || element.store_id != 0">{{index+1}}. {{generateName(element)}}</option>
                                </template>
                            </select>
                        </td>
                        <td>
                            <select class="form-control form-control-sm" v-model="condition.article_detail_id">
                                <option :value="null">-- Wähle Artikel --</option>
                                <template v-for="(element, index) in lists.articles">
                                    <option :key="element.id" :value="element.article_detail_id" v-if="element.not_available != 1 && element.article_detail_id != 0 ">{{index+1}}. {{element.name}}</option>
                                </template>
                            </select>
                        </td>
                        <td>
                            <input type="number" class="form-control form-control-sm" v-model="condition.qty" min="1">
                        </td>
                        <td>
                            <button class="btn btn-danger btn-sm" v-b-popover.hover.top='"Löschen"' @click="conditions.splice(index, 1)">
                                <i class="fas fa-trash"></i>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="card-tools text-right">
                <button class="btn btn-primary btn-sm" :disabled="conditions.length <= 0" @click="submitConditionsModal()">
                    <i class="fas fa-plus"></i> Bedingung hinzufügen
                </button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>

import draggable from 'vuedraggable'
import {mapState} from 'vuex';

//Own components
import Map from './components/Map.vue'

//Modals
import NoticeModal from './components/modals/notice.vue'
import DriversMessageModal from './components/modals/driversMessage.vue'
import ProofOfDeliveryModal from './components/modals/proofOfDelivery.vue'
import AddRouteElementModal from './components/modals/addRouteElement.vue'
import AddAlternativeArticle from './components/modals/addAlternativeArticle.vue'
import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';

export default {
    name: 'RouteDetails',
    title: 'Route bearbeiten',
    components: { 
        draggable, 
        Map,
        NoticeModal,
        DriversMessageModal,
        ProofOfDeliveryModal,
        AddRouteElementModal,
        AddAlternativeArticle,
    }, 
    data() {
        return {
            route: [],
            cars: [],
            groups: [],
            filter: null,
            id: null,
            selectedRow: {},
            selectedCell: null,
            states: [],
            test: null,
            states_conditions: {},
            selectedStopps: [],
            img: '',
            route_details: {
                distance: 0,
                duration: 0,
                duration_history: [],
            },
            lists: {
                articles: {},
            },
            conditions: [{route_element_target_id: null, article_detail_id: null, qty: 1}],
            not_available: {
                id: null
            },
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
            sendLaForm: new window.Form({
                emails: [],
                selected_emails: [],
                message: '',
                type: 'mail',
            })
        };
    },
    methods: {
        perlschnur()
        {
            this.axios
                .get("/routes/" + this.$route.params.id + "/perlschnur", {
                    route_elements: this.route.route_elements
                })
                .then((response) => {
                    this.route.route_elements = [];
                    this.route.route_elements = response.data.data;
                    this.route.route_elements.forEach(function(element, index) {
                        element.position = index;
                    });
                    
                    this.$swal({
                        icon: "success",
                        title: "Strecke wurde optimiert.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    });
                })
        },
        checkConditionAfterSource(element)
        {
            let isAfter = false;
            element.route_element_conditions.forEach(elem => {
                let target = this.route.route_elements.find(el => el.id == elem.target.id);
                if(target != null)
                {
                    if(target.position > element.position)
                    {
                        isAfter = true;
                    }
                }
            })
            return isAfter;
        },

        hasConditions(element)
        {
            let hasConditions = false;
            this.route.route_elements.forEach(ele => {
                ele.route_element_conditions.forEach(cond => {
                    if(cond.target.id == element.id)
                    {
                        hasConditions = true;
                    }
                })
            })
            return hasConditions;
        },
        count(element)
        {
            let amount = 0;
            if(element.orders != null)
            {
                element.orders.items.forEach(el => {
                    if(el.not_available == 1)
                    {
                        amount += 1;
                    }
                })
            }
            return amount;
        },
        /*

        Abstellnachweis

        */
        openDeliveryImage(element)
        {
            this.img = element.deliveryImage;
            this.$bvModal.show('proof_of_delivery');
        },
        openalternativeArticleModal()
        {
            this.$bvModal.show('alternativeArticle');
        },

        /*

        Route_elements conditions

        */
        openConditionsModal(element)
        {
            this.conditions = [{route_element_target_id: null, article_detail_id: null, qty: 1}];
            this.$bvModal.show('routeConditions');
            this.loadArticles(element);
            this.id = element.id;
        },
        closeConditionsModal()
        {
            this.$bvModal.hide('routeConditions');
        },
        submitConditionsModal()
        {
            const status_id = this.states_conditions.find(element => element.name == 'idle');
            this.axios
                .post('/route-element-conditions',
                {
                    route_element_source_id: this.id,
                    // route_element_target_id: this.conditions.route_element,
                    // article_detail_id: this.conditions.article,
                    // qty: this.conditions.qty,

                    conditions: this.conditions,

                    status_id: status_id.id
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Artikel aus bedingung gelöscht.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.changeOrder();
                    this.closeConditionsModal();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },

        deleteCondition(id, routeElementIndex, conditionIndex)
        {
            this.route.route_elements[routeElementIndex].route_element_conditions.splice(conditionIndex, 1);
            this.axios
                .delete('/route-element-conditions/'+ id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Bedingung gelöscht.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$router.push({name: 'routes.index'});
                    this.$swal({
                        icon: "error",
                        title: "Diese Strecke wurde bereits geschlossen.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
        },
        deleteConditionArticle(id, routeElementIndex, conditionIndex, conditionArticleIndex)
        {
            this.route.route_elements[routeElementIndex].route_element_conditions[conditionIndex].items.splice(conditionArticleIndex, 1);

            this.axios
                .delete('/route-element-condition-article/'+ id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Artikel aus bedingung gelöscht.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$router.push({name: 'routes.index'});
                    this.$swal({
                        icon: "error",
                        title: "Diese Strecke wurde bereits geschlossen.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
        },
        
        /*

        Artikel nicht lieferbar

        */
        openArticleModal(route_element)
        {
            this.$bvModal.show('articleNotAvailable');
            this.id = route_element.id;
            this.not_available.id = null;

            this.loadArticles(route_element);
        },
        closeArticleModal()
        {
            this.$bvModal.hide('articleNotAvailable');
        },

        submitArticleModal(element_id, order_item_id)
        {
            let element = this.route.route_elements.find(element => element.id == element_id);
            let item = element.orders.items.find(element => element.id == order_item_id.id);
            item.not_available = 1;
            item.not_available_qty = this.not_available.id.not_available_qty;

            item.alternative_article = [];
            if(this.not_available.id.alternative_article != null)
            {
                this.not_available.id.alternative_article.forEach(alt_item => {
                    item.alternative_article.push(alt_item);
                });
            }


            this.closeArticleModal();
            this.axios
                .post("/route-elements/updateOrder", {
                    order_item_id: order_item_id.id,
                    not_available: 1,
                    alternative_article: item.alternative_article,
                    not_available_qty: this.not_available.id.not_available_qty
                })
                .then(() => {

                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        deleteArticleModal(element_id, order_item_id)
        {
            let element = this.route.route_elements.find(element => element.id == element_id);
            let item = element.orders.items.find(element => element.id == order_item_id);
            item.not_available = 0;
            item.alternative_article = [];

            this.axios
                .post("/route-elements/updateOrder", {
                    order_item_id,
                    not_available: 0,
                    alternative_article: [],
                    not_available_qty: 0
                })
                .then(() => {

                })
                .catch(() => {
                    this.$router.push({name: 'routes.index'});
                    this.$swal({
                        icon: "error",
                        title: "Diese Strecke wurde bereits geschlossen.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
        },

        loadArticles(element)
        {
            this.axios
                .get("/orders/" + element.order_id)
                .then((response) => {
                    this.lists.articles = response.data.data.items;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },


        /*

        Drag and Drop

        */
        drop()
        {
            this.route.route_elements.forEach(function(element, index) {
                element.position = index;
            });
            this.updateTime();
        },
        checkMove(e) {
            let {index, futureIndex} = e.draggedContext;
            return !(this.route.route_elements[index].status.name == 'completed' || this.route.route_elements[futureIndex].status.name == 'completed' || this.route.route_elements[index].status.name == 'next_station' || this.route.route_elements[futureIndex].status.name == 'next_station');
        },


        /*

        Some helpers

        */
        calculateRouteElementArrivelTime(index)
        {
            let date = new Date();
            date.setUTCHours(this.route.start_time.split(':')[0]);
            date.setUTCMinutes(this.route.start_time.split(':')[1]);
            date.setUTCSeconds(this.route.start_time.split(':')[2]);

            for(let i = 0; i < index+1; i += 1)
            {  
                let hours = 0;
                let minutes = 0;

                //Zwischenpause bekommen
                let minutes_sonst = 0;
                if(this.route_details.duration_history[i-1] != null)
                {
                    minutes_sonst = this.route.route_elements[i-1].needed_time;
                }


                if(this.route.route_elements[i].finish_time == null)
                {
                    //Zeit berechnen aus History
                    hours = Math.floor(this.route_details.duration_history[i] / 3600);
                    minutes = Math.floor(this.route_details.duration_history[i] % 3600 / 60);
    
                    //Zeit setzen
                    hours = date.getUTCHours() + hours;
                    minutes = date.getUTCMinutes() + minutes + minutes_sonst;
                } else {
                    hours = parseInt(this.route.route_elements[i].finish_time.split(':')[0]);
                    minutes = parseInt(this.route.route_elements[i].finish_time.split(':')[1]);
                }
                date.setUTCHours(hours);
                date.setUTCMinutes(minutes);
            }

            return date.getUTCHours() + ":" + date.getMinutes() + " Uhr";
        },
        calculateRouteElementArrivelTimeObject(index)
        {
            let date = new Date();
            date.setUTCHours(this.route.start_time.split(':')[0]);
            date.setUTCMinutes(this.route.start_time.split(':')[1]);
            date.setUTCSeconds(this.route.start_time.split(':')[2]);

            for(let i = 0; i < index+1; i += 1)
            {  
                let hours = 0;
                let minutes = 0;

                //Zwischenpause bekommen
                let minutes_sonst = 0;
                if(this.route_details.duration_history[i-1] != null)
                {
                    minutes_sonst = this.route.route_elements[i-1].needed_time;
                }


                if(this.route.route_elements[i].finish_time == null)
                {
                    //Zeit berechnen aus History
                    hours = Math.floor(this.route_details.duration_history[i] / 3600);
                    minutes = Math.floor(this.route_details.duration_history[i] % 3600 / 60);
    
                    //Zeit setzen
                    hours = date.getUTCHours() + hours;
                    minutes = date.getUTCMinutes() + minutes + minutes_sonst;
                } else {
                    hours = parseInt(this.route.route_elements[i].finish_time.split(':')[0]);
                    minutes = parseInt(this.route.route_elements[i].finish_time.split(':')[1]);
                }
                date.setUTCHours(hours);
                date.setUTCMinutes(minutes);
            }

            return {
                hours: date.getUTCHours(),
                minutes: date.getMinutes(),
            }
        },
        showOrder(order) {
            this.route.route_elements.forEach(function(element, index) {
                element.position = index;
            });
            this.selectedCell = null;
            this.selectedRow = {};
            this.axios
                .put('/routes/' + this.$route.params.id, {
                    id: this.route.id,
                    car_id: this.route.car_id,
                    name: this.route.name,
                    deliveryDay: this.route.deliveryDay,
                    drivers_name: this.route.drivers_name,
                    route_elements: this.route.route_elements,
                    employee_id: this.route.employee_id,
                })
                .then(() => {
                    this.$router.push({name: 'orders.details', params: {id: order.id}});
                })
                .catch(() => {
                    this.$router.push({name: 'routes.index'});
                    this.$swal({
                        icon: "error",
                        title: "Diese Strecke wurde bereits geschlossen.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
        },
        setBadge(element) {
            if(element.status.name == 'open')
            {
                return 'badge-danger';
            } else if(element.status.name == 'completed')
            {
                return 'badge-success';
            } else if(element.status.name == 'next_station')
            {
                return 'badge-warning';
            }
            
            return 'badge-primary';
        },
        removeAlternativeArticle(route_element_id, item_id, article_detail_id)
        {
            let route_element = this.route.route_elements.find(element => element.id == route_element_id);
            let item = route_element.orders.items.find(element => element.id == item_id);
            item.alternative_article.splice(article_detail_id, 1);

            let alt_articles_string = item.alternative_article;
            this.axios
                .post("/route-elements/updateOrder", {
                    order_item_id: item_id,
                    not_available: 1,
                    alternative_article: alt_articles_string
                })
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Alternative gelöscht",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        },
        removeAlternativeArticleModal(index)
        {
            this.not_available.id.alternative_article.splice(index, 1);
            console.log(this.not_available.id.alternative_article);
        },

        /*

        get new Duration History for all Elements

        */
        updateTime()
        {
            this.axios
                .post("/routes/getDistanceDuration", {
                    route_elements: this.route.route_elements
                })
                .then((response) => {
                    this.route_details = response.data.data;
                    this.route.route_elements.forEach(function(element, index) {
                        element.position = index;
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                });
        },
        openNoticeModal(id)
        {  
            this.$bvModal.show('notice');
            this.id = id;
        },
        openDriversMessageModal(id)
        {  
            this.$bvModal.show('driversMessage');
            this.id = id;
        },
        deleteElement(id, index)
        {
            this.route.route_elements.splice(index, 1);
            this.route.route_elements.forEach(function(element, index) {
                element.position = index;
            });

            this.axios
                .delete('/route-elements/' + id)
                .then(() => {
                    this.updateTime();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                })
        },
        reload()
        {
            this.loadRoute();
        },
        createModal() {
            this.$bvModal.show("dealerModal");
        },
        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        handleEditCell(index, name) {
            this.selectedCell = name;
            this.selectedRow = {
            [index]: !this.selectedRow[index]
            }
        },
        loadRoute()
        {
            this.axios
                .get('/routes/' + this.$route.params.id)
                .then((response) => {
                    this.selectedCell = null;
                    this.selectedRow = {};
                    this.route = response.data.data;
                    this.route.route_elements.forEach(function(element, index) {
                        element.position = index;
                    });
                    this.updateTime();
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.'
                    })
                });
        },
        changeOrder()
        {
            this.route.route_elements.forEach(function(element, index) {
                element.position = index;
            });
            this.selectedCell = null;
            this.selectedRow = {};
            this.axios
                .put('/routes/' + this.$route.params.id, {
                    id: this.route.id,
                    car_id: this.route.car_id,
                    name: this.route.name,
                    deliveryDay: this.route.deliveryDay,
                    drivers_name: this.route.drivers_name,
                    route_elements: this.route.route_elements,
                    employee_id: this.route.employee_id,
                })
                .then(() => {
                    this.loadRoute();
                    // this.updateTime();
                })
                .catch(() => {
                    this.$router.push({name: 'routes.index'});
                    this.$swal({
                        icon: "error",
                        title: "Diese Strecke wurde bereits geschlossen.",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
        },


        /*

        Load All Cars
0
        */
        loadCars() {
            return this.axios
                .get('/cars')
                .then((response) => {
                    this.cars = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
        
        
        /*

        Load States

        */
        loadStates()
        {
            this.axios
                .get("/states/route-element")
                .then((response) => {
                    this.states = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen.',
                    });
                });
        },
        loadConditionStates()
        {
            this.axios
                .get("/states/route-element-condition")
                .then((response) => {
                    this.states_conditions = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },
        sendLA() {
            this.sendLaForm
                .post('/mail/send/la')
                .then(() => {
                    this.loadRoute();
                    this.$swal({
                        icon: "success",
                        title: "Lieferavisierung wurde gesendet",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                });
        },
        phoneLA(order, index) {
            var time = this.calculateRouteElementArrivelTimeObject(index);
            this.sendLaForm.order_id = order.id;
            this.sendLaForm.message = "Telefonische Lieferavisierung: " + order.phone + " <br> zwischen " + (time.hours-2) + " und " + (time.hours+2) + " Uhr";
            this.sendLaForm.type = 'phone';
            this.$bvModal.show('laModalPhone');
        },
        getLa(order, index)
        {
            // console.log(this.calculateRouteElementArrivelTimeObject(index));
            this.sendLaForm.emails = [];
            this.sendLaForm.selected_emails = [];
            this.sendLaForm.message = "";
            this.sendLaForm.order_id = null;
            this.axios
                .post('mail/get/la', {
                    'id': order.id,
                    'start': this.calculateRouteElementArrivelTimeObject(index).hours-2,
                    'stop': this.calculateRouteElementArrivelTimeObject(index).hours+2,
                })
                .then((response) => {
                    this.$bvModal.show('laModal');
                    this.sendLaForm.message = response.data.data;
                    this.sendLaForm.emails.push(order.email);
                    this.sendLaForm.selected_emails.push(order.email);
                    this.sendLaForm.order_id = order.id;
                    this.sendLaForm.type = 'mail';
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Da ist wohl etwas schief gelaufen'
                    })
                })
        },



        /*

        Generate name for a element and generate Variation name for a article

        */
        generateName(element, //Route_Element
                    hide_order = false //Hide the orders element
                    )
        {
            if(element != null)
            {
                if(element.own_addresses != null)
                {
                    return element.own_addresses.name + ' (Benutzerdefiniert)';
                } else if(element.saved_addresses != null)
                {
                    return element.saved_addresses.name + ' (Gespeichert)';
                } else if(element.dealers != null)
                {
                    return element.dealers.name + ' (Händler)';
                } else if(element.wholesalers != null)
                {
                    return element.wholesalers.name + ' (Großhändler)';
                } else if(element.producers != null)
                {
                    return element.producers.name + ' (Lieferant)';
                } else if(element.stores != null)
                {
                    return element.stores.name + ' (Filiale)'
                } else if(hide_order == false) {
                    if(element.orders != null)
                    {
                        return element.orders.shipping.first_name + ' ' + element.orders.shipping.last_name + ' (' + element.orders.ordernumber +')';
                    }
                }
            }
        },
        generateVariationName(article)
        {
            let name = article.article_details.article.name;
            
            article.article_details.values.forEach(element => {
                name += " " + element.name;
            })
            return name;
        },
        closeRoute()
        {
            this.$swal({
                title: "Möchtest du die Route wirklich beenden?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, beenden`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .post("/routes/" + this.$route.params.id + "/changeStateByName", {
                            'name': 'rework'
                        })
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Streckenplanung beended!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.$router.push({name: 'routes.index'});
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },
        getEmployees()
        {
            this.axios
                .get("/employee/groups")
                .then((response) => {
                    this.groups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Beim laden der Mitarbeiter ist etwas schief gelaufen'
                    })
                })
        }
    },
    mounted() {
        this.loadCars();
        this.loadStates();
        this.loadConditionStates();
        this.loadRoute();
        this.getEmployees();
    },
    computed: {
        ...mapState('routes', ['params', 'search', 'page']),
    }
}
</script>
<style>
    .sortable-chosen {
        opacity: 0.2;
        border: solid black 3px;
    }
</style>